import comboCardShopZh from '@/lang/combo-card-shop/zh'

export default {
  route: {
    // 新增
    PriceChangeRecord: '商品改价记录',
    PriceChangeRecordComboCard: '商品改价记录',
    // 新增

    development: '开发',
    toolManage: '工具',
    menuManage: '菜单管理',
    privateGoodsPublish: '添加商品',
    dashboard: '首页',
    home: '首页',

    Invoicereview: '发票审核',
    lookInvoicereview: '查看',
    Invoicemanger: '发票管理',
    userList: '用户管理',
    users: '用户',
    usersMsg: '用户详情',
    fzadmin: '用户分组管理',
    userschong: '用户充值管理',
    chongMsg: '充值详情',

    promotion: '促销',
    cashCoupon: '储值卡生成',
    cashCouponList: '储值卡列表',
    coupon: '优惠券生成',
    couponList: "优惠券列表",
    exchangeCodelist: '兑换码生成记录',
    exchangeCodelist1: '兑换码生成记录',

    supplier: '供应商',
    qualifications: '资质',
    shopGoodsa: "商品集市",
    transportg: "运费设置",
    markets: '集市',
    market: '集市首页',
    market1: '集市精选',
    marketGoods: '商品列表',
    marketGood: '商品详情',
    privateGoods: '自有商品',
    first: '首页',
    handleLog: '操作日志',
    handleLoga: '操作日志',
    compromote: '企业升级',
    Invoicemangerg: '发票审核',

    goods: '商品',
    goodsList: '商品列表',
    goodsListzd: '商品列表站点',
    goodsChange: '信息变更记录',
    goodsImportList: "商品导入记录",
    goodPublish: '商品添加',
    goodEdit: '商品编辑',
    draftList: '草稿箱',
    intelOperation: '智能运营',
    categoryManage: '分组管理',
    tagManage: '标签管理',
    tagManages: '标签管理',
    tagAdd: '标签商品',
    shopTagAdd: '标签商品',
    recycleStation: '回收站',
    understock: '预警货品',
    shopMesg: '站点信息',
    shareSetting: '分享设置',
    shopPayment: '支付方式',
    shopPerson: '个人设置',
    shopAddress: '信息设置',
    shopAddressa: '信息设置',
    shopCompanyList: '物流公司',
    shopCompanyLista: '物流公司',
    safeSetting: '安全设置',
    shopIdentify: '企业认证',
    mallIdentify:'站点认证',
    giftLableList: '礼包标签管理',
    revoke_card_key:'卡密撤销记录',
    povertyAlleviation: '入口页管理',
    povertyAlleviationWap: '入口页管理',

    finance: '财务',
    overview: '账户概览',
    summary: '账单汇总',
    financeSummary: '财务汇总',
    detailed: '账单明细',
    financeDetailed: '财务明细',
    dealDetailed: '交易明细',
    openCardWrite: '销售单核销',
    closeCardWrite: '关卡核销',
    siteFinance: '站点财务',
    financeAccount: '提现账户',
    financeAccounta: '提现账户',
    financeAccountg: '提现账户',
    guaranteeMoney: '质保金管理',
    invoice: '发票审核',
    enterpriseOverview: '账户概览',
    shopOverview: '账户概览',
    info: '通知',
    infoList: '通知提醒',
    noticeIdentify:'公告发布',
    account: '对账',
    accountOrder: '对账订单',
    accountList: '对账列表',
    accountDetail: '对账详情',
    agreeIn: '用户协议',
    agreeIna: '免责声明',
    shopGoods: '商品',
    shopGoodsList: '店铺商品',
    shopGoodPublish: '供应商商品详细',
    programmeList: '套餐方案列表',
    programmeRecord: '销售方案记录',
    programmeDetail: "方案详情",

    orderList: '订单列表',
    enterpriseOrderList: '订单列表',
    shopOrderList: '现金商城订单',
    comboOrderList: '套餐卡订单',
    aiComboOrderList: '智能套餐卡订单',
    shopRefundList: '现金商城订单',
    comboRefundList: '套餐卡订单',
    aiComboRefundList: '智能套餐卡订单',
    logList: '导出日志',
    ranking: '排行榜',
    serverList: '服务订单',
    couponOrderList: '卡券订单',
    unionOrderList: '订单列表',
    unionRefundList: '维权订单',
    unionExportList: '导出记录',
    orderLista: '订单列表',
    orderListb: '自有商品订单列表',
    refundList: '维权订单',
    enterpriseRefundList: '维权订单',
    refundDetail: '维权详情',
    refundLista: '维权订单',
    order: '订单',
    logisticsManage: '物流管理',
    commentsManage: '评价管理',
    settlementManage: '结算管理',
    settlementDetail: '结算详情',
    receiptHistory: '发票历史',
    orderDetail: '订单详情',
    supplementList: '套餐礼包补单',

    shop: '店铺管理',
    shopThemesPc: '店铺模板',
    shopThemesWap: 'Wap店铺',
    shopSlide: '店铺幻灯',
    shopNav: '店铺导航',

    member: '会员',
    memberManage: '管理会员',
    memberList: '会员列表',
    memberStaffList: '员工会员',
    unionMember: '企业会员',
    seeImport: '查看导入批次',
    memberRecycle: '会员回收站',
    goodsComment: '商品评论',
    goodsCommentList: '商品评论列表',
    goodsAskList: '商品咨询列表',
    memberNotification: '会员通知',
    notificationHistory: '站内消息',
    memberEdit: '编辑会员',

    promotions: '促销',
    fullCut: '满减满赠',
    singleCut: '单品立减',
    secondHalfPrice: '第二件半价',
    discountManager: '优惠券管理',
    giftManager: '赠品管理',
    groupBuyManager: '团购管理',
    timeLimit: '限时抢购',
    addTimeLimit: '新增限时抢购',
    activityGoodsData: '活动商品信息',
    groupBuyGoods: '团购商品',

    statistics: '数据',
    revenue: '营收数据',
    dataToday: '今日数据',
    dealRevenue: '营收数据',
    orderStatistics: '订单数据',
    goodsStatistics: '商品数据',
    generalityOverview: '店铺概况',
    goodsAnalysis: '商品分析',
    goodsDetailsAnalysis: '商品详情',
    goodsPriceSales: '价格销量',
    hotSellingGoods: '热卖商品',
    operateReport: '运营报告',
    buyAnalysis: '购买分析',
    salesStatistics: '销售统计',
    regionalAnalysis: '区域分析',
    trafficStatistics: '流量统计',
    collectStatistics: '收藏统计',
    logManage: '日志管理',
    shopFlow: '流量统计',
    shopUser: '用户数据',
    shopActive: '活跃数据',
    shopDataToday: '今日数据',
    shopDataDaily: '日常数据',
    loginLog: '登录日志',
    loginLoga: '登录日志',

    application: '应用',
    shopManagement: '商城管理',
    setMeal: '套餐卡管理',
    setMealAdd: '添加套餐卡券',
    czkMeal: '充值卡管理',
    czkMealAdd: '添加充值卡券',
    czkMealList: '充值卡列表',
    grant: '卡券发放情况',
    grantDetails: '卡券详情',
    myGrant: '套餐卡券列表',
    grantUse: '卡券使用情况',
    grantDetail: '使用详情',
    fabjlist: '方案报价列表',
    fabjlista: '方案报价列表',

    customer: '客服',
    consultation: '咨询管理',
    message: '消息',


    shopAuth: '店员',
    shopAssistant: '店员列表',
    roleManage: '角色管理',
    rolePermission: '角色权限',
    cardSaleManage:"销售人员管理",

    setting: '设置',
    shopSettings: '店铺设置',
    address: '地址管理',
    goodsWarning: '货品预警',
    gradeApplication: '等级申请',
    shopTransportationExp: '运费设置',

    page: '页面',
    pcDecoration: 'PC装修',
    pcThemeManage: '模板管理',
    pcFloorManage: '楼层装修',
    fpFloorManage: '京东馆装修',
    fpMobileFloorManage: '京东馆装修',
    pcFocusManage: '焦点图管理',
    mobileDecoration: '移动端装修',
    mobileThemeManage: '模板管理',
    mobileFloorManageA: '楼层装修',
    mobileFocusManage: '焦点图管理',
    pageSetting: '页面设置',
    pcSiteMenu: 'PC导航菜单',
    mobileSiteMenu: '移动端导航菜单',
    hotKeyword: '热门关键字',
    childMobileFloorManage: '子楼层装修',
    childFloor: '子级楼层',
    shopAuthq: '权限',
    shopAssistantq: '管理员管理',
    messageManage: '短信签名管理',
    messageGai: '短信概览',
    messagePei: '短信签名配置',
    sencond: '首页',
    loginJournal: '登录日志',
    operationJournal: '操作日志',
    specialList: '商品专题',

    //工具模块
    tools: '工具',
    promotionManage: '促销管理',
    storedCardList: '储值卡列表',
    saleManage: '销售管理',
    salesList: '销售员列表',
    shareVideoList: '分享文案列表',
    saleCardOpenManagement: '销售开卡',
    saleCardCloseManagement: '销售关卡',
    cardSearch: '卡号查询',

    // 以下为新加路由
    shopMarket: 'shopMarket',
    marketGooda: 'marketGooda',
    marketGoodb: 'marketGoodb',
    goodEdit1: 'goodEdit1',
    requestInvoice: 'requestInvoice',
    administer: 'administer',
    Invoicemangerdetail: 'Invoicemangerdetail',
    Invoicemangerjilu: 'Invoicemangerjilu',
    Invoicemangerjiludetail: 'Invoicemangerjiludetail',
    mallManagementEdit: 'mallManagementEdit',

    ...comboCardShopZh
  },
  login: {
    logIn: '登录',
    username: '请输入管理员名称',
    password: '请输入管理员密码',
    validcode: '请输入图片验证码',
    val_username: '请输入管理员名称',
    val_password: '请输入管理员密码',
    val_validcode: '请输入图片验证码',
    val_form: '表单填写有误，请检查！'
  },
  tagsView: {
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  }
}
