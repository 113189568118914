<template>
  <x-dialog :proxy="goodsSelectorDialog">
    <en-table-layout ref="tables" :table-data="tableData.data" :row-key="row => row.id" :redundancy-height="50"
      @selection-change="selectionChange" @select="handleSelect" @select-all="handleSelectAll"
      @sort-change="handleSortChange">
      <template slot="toolbar">

        <el-form-item style="width:300px">
          <el-input size="small" v-model="params.lowEnterPrice" placeholder="￥分销总价最小" style="width:130px;" />
          <span style="margin: 0 6px;letter-spacing: -2px;color:#999999;">---</span>
          <el-input size="small" v-model="params.highEnterPrice" placeholder="￥分销总价最大" style="width:130px;" />
        </el-form-item>
        <el-form-item style="width:300px">
          <el-input size="small" v-model="params.lowMktPrice" placeholder="￥市场总价最小" style="width:130px;" />
          <span style="margin: 0 6px;letter-spacing: -2px;color:#999999;">---</span>
          <el-input size="small" v-model="params.highMktPrice" placeholder="￥市场总价最大" style="width:130px;" />
        </el-form-item>
        <el-form-item label="礼包分类" label-width="70px" style="margin-bottom:0">
          <el-cascader clearable style="width: 215px;" v-model="params.categoryPath" :options="JDCategory.children"
            :props="JDCategory.props" placeholder="请选择礼包分类" class="cas-select" />
        </el-form-item>
        <el-form-item>
          <el-input size="small" placeholder="请输入礼包名称" v-model="params.keyword" clearable></el-input>
        </el-form-item>
        <el-form-item label="是否异常" label-width="80px" style="width:171px">
          <el-select v-model="params.normalStatus" style="width: 90px">
            <el-option value label="全部"></el-option>
            <el-option :value="0" label="异常"></el-option>
            <el-option :value="1" label="正常"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" size="mini" @click="GET_Goods">
          查询
        </el-button>


        <div class="col"></div>
        <el-button v-if="!showChooseBtn" type="primary" style="margin:10px 0 0 5px;" size="mini" @click="chooseAll">
          挑选至卡券
        </el-button>
      </template>

      <template slot="table-columns">
        <el-table-column v-if="!showChooseBtn" :reserve-selection="true" type="selection" width="55" />

        <el-table-column label="礼品图片" prop="thumbnail" width="60" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt="" style="width: 300px">
              <!-- <img :src="row.thumbnail" class="goods-cover" alt="" slot="reference" /> -->
              <div slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="礼品名称" prop="" width="400" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.gift_name }}
          </template>
        </el-table-column>
        <el-table-column label="礼包分类" :key="'category_name'" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.category_name }}
          </template>
        </el-table-column>
        <el-table-column label="套餐价">
          <template slot-scope="scope">
            {{ scope.row.combo_price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="销售总价">
          <template slot-scope="scope">
            {{ scope.row.shop_sum_price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="市场总价">
          <template slot-scope="scope">
            {{ scope.row.market_sum_price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="分销总价">
          <template slot-scope="scope">
            {{ scope.row.enterprise_sum_price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="会员总价" v-if="MixinIsFormEnterprise" sortable="custom" prop="sum_price">
          <template slot-scope="scope">
            {{ scope.row.sum_price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="利润率" v-if="MixinIsFormEnterprise" sortable="custom" prop="shop_profit">
          <template slot-scope="scope">
            {{ (scope.row.shop_profit || 0) | unitCompare('%') }}
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-size="tableData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="tableData.data_total" :pager-count="13">
      </el-pagination>
    </en-table-layout>
  </x-dialog>
</template>

<script>
import mixin from './mixin';
import EnTableLayout from '../../TableLayout/src/main';
import XDialog from '@/components/x-dialog/x-dialog';
import XSortable from '@/components/x-sortable/x-sortable';

export default {
  name: 'ComboPicker',
  components: {
    XSortable,
    XDialog,
    EnTableLayout
  },
  mixins: [mixin]
};
</script>

<style lang="scss">
.goods-selector-dialog {
  .el-tabs__header {
    margin: 0;
  }

  .el-scrollbar__view {
    height: 80vh;
  }
}
</style>

