const api = require('./api')
const domain = require('./domain')

const env = process.server
  ? process.env
  : (global.window ? window.__NUXT__.state.env : {})

// 后台提供的API模式【dev|pro】
const api_model = env.API_MODEL || 'dev'
// 当前前台是否为开发模式
const reg = /^(?:[1-9]\d*|0)(?:\.\d+)?$/;
let ip = location.host.split('.')[0]
const is_dev = reg.test(ip)|| location.host.includes('test') || location.host.includes('localhost') || location.host.includes('172.20') || location.host.includes('172.18') || location.host.includes('192.168');

module.exports = {
  // API模式
  api_model,
  // 开发环境下的API0
  api_dev: api.dev,
  // 生产环境下的API
  api_pro: api.pro,
  // 开发环境下的域名
  domain_dev: domain.dev,
  // 生产环境下的域名
  domain_pro: domain.pro,
  // API根据前台环境自动适配
  api: is_dev ? api.dev : api.pro,
  // 域名根据前台环境自动适配
  domain: is_dev ? domain.dev : domain.pro
}
