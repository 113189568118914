<template>
  <div>
    <script :id="randomId" type="text/plain"></script>
  </div>
</template>

<script>
import { api } from '@/../ui-domain'
import '@/../public/UE/ueditor.config.js'
import '@/../public/UE/ueditor.all.js'
import '@/../public/UE/lang/zh-cn/zh-cn.js'
import '@/../public/UE/ueditor.parse.js'
export default {
  name: 'UE',
  data () {
    return {
      /** 编辑器实例 */
      editor: null,

      /** 每个编辑器生成不同的id,以防止冲突 */
      randomId: 'editor_1' + parseInt(Math.random() * 10000 + 1),

      ready: false
    }
  },
  props: {
    defaultMsg: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => ({
        serverUrl: `${api.base}/ueditor/`,
        // toolbars: [
        //   [
        //     'fullscreen', 'source', '|', 'undo', 'redo', '|',
        //     'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
        //     'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
        //     'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
        //     'directionalityltr', 'directionalityrtl', 'indent', '|',
        //     'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
        //     'touppercase', 'tolowercase', '|',
        //     'link', 'unlink', 'anchor', '|',
        //     'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
        //     'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'music', 'attachment', 'map', 'gmap', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template', 'background', '|',
        //     'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
        //     'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcolumn', 'deletecolumn', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', '|', 'print', 'preview', 'searchreplace', 'help', 'drafts', '|',
        //   ]
        // ]
      })
    }
  },
  watch: {
    defaultMsg (newVal, oldVal) {
      if (newVal != null && this.ready) {
        this.editor.setContent(newVal || '')
      }
    }
  },
  mounted () {
    this.initEditor()
  },
  methods: {
    /** 初始化编辑器 */
    initEditor () {
      this.$nextTick(() => {
        this.editor = window.UE.getEditor(this.randomId, this.config)
        this.editor.addListener('ready', () => {
          this.ready = true
          this.editor.setContent(this.defaultMsg)
        })
      })
    },

    getUEContent () {
      return this.editor.getContent()
    }
  },
  destroyed () {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.edui-default {
  * {
    line-height: 1;
  }
}
</style>
