<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    top="10vh"
    width="805px"
  >
    <div slot="title" class="goods-picker-title">
      <span></span>
      核销权限设置
    </div>
    <div class="goods-picker-body">
      <p>提示：请挑选要查看哪些销售人员的核销单据</p>
      <div class="goods-category">
        <dl>
          <dt><el-checkbox v-model="allChecked"></el-checkbox>全选</dt>
          <div class="input-with-select">
            <el-input placeholder="请输入姓名" size="small" v-model="searchName" @keyup.enter.native="handleSearch" clearable>
              <el-button slot="append" @click="handleSearch" icon="el-icon-search"></el-button>
            </el-input>
          </div>
          <div class="kj">
            <dd v-for="item in selectList" :key="item.id">
              <el-checkbox v-model="item.checked" @change="changeAuth($event,item)"></el-checkbox>
              <span>{{item.name}}（{{item.mobile}}）</span>
            </dd>
          </div>
        </dl>
        <span>
          <!-- :disabled="!selectLen" @click="handleToAuth" -->
          <el-button type="primary" style="cursor: default; pointer-events: none;">
            <i class="el-icon-arrow-right el-icon--right"></i>
          </el-button>
        </span>
        <dl>
          <dt>已选人员：{{ selectedLen }} 人</dt>
          <div class="kj kj2">
            <dd v-for="item in authList" :key="item.id">
              <el-checkbox v-model="item.checked" @change="delAuth(item)"></el-checkbox>
              <span>{{item.name}}（{{item.mobile}}）</span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div slot="footer" class="goods-picker-footer">
      <span>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </div>
  </el-dialog>
</template>
<script>
import mixin from "./mixin";

export default {
  name: "EnCardAuthPicker",
  mixins: [mixin],
  computed:{
    selectLen() {
      return this.getCheckList(this.selectList).length || 0;
    },
    selectedLen() {
      return this.getCheckList(this.authList).length || 0;
    }
  }
};
</script>
<style type="text/scss" lang="scss" scoped>
@import "./styles";
.params-ids-select-dropdown {
  display: none !important;
}
.kj {
  height: 320px;
  overflow: auto;
  &.kj2 {
    height: 366px;
  }
}

.goods-category {
  display: flex;
  align-items: center;
  justify-content: center;
  &>span{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .el-button{
      padding: 15px 10px 15px 5px;
    }
  }
  dl {
    width: 45%;
    display: inline-block;
    border: 1px solid rgba(230, 236, 247, 1);
    vertical-align: top;
    border-radius: 5px;
  }
  dd {
    margin-inline-start: 0;
    height: 32px;
    margin: 0;
    border-top: 1px solid #efefef;
    cursor: pointer;
    font-size: 13px;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
    /deep/ .el-checkbox{
      margin: 0 5px 0 0;
    }
  }
  dt {
    font-size: 15px;
    color: white;
    border-radius: 5px 5px 0 0;
    line-height: 40px;
    background: rgba(26, 67, 169, 1);
    text-align: left;
    box-sizing: border-box;
    padding: 0 15px;
    height: 40px;
    display: flex;
    align-items: center;
    /deep/ .el-checkbox__input {
      margin: 0 5px 0 0;
      vertical-align: -webkit-baseline-middle;
    }
  }
}
.input-with-select {
  width: 100%;
  box-sizing: border-box;
  padding: 7px 10px;
}

.goods-picker-body {
  & > p {
    margin-bottom: 5px;
    box-sizing: border-box;
    padding: 0 0px;
  }
  /deep/ .el-input__inner {
    height: 32px;
  }
}
</style>
