import router, { asyncRouterMap } from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { MessageBox } from "element-ui";
import Storage from "@/utils/storage";
import * as API_Shop from "@/api/shop";
import { domain } from "@/../ui-domain";
import Vue from "vue";

const whiteList = ["/login"];

async function routerBeforeEach(to, from, next) {
  NProgress.start();

  const refreshToken = Storage.getItem("seller_refresh_token");

  if (refreshToken) {
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      // 获取店铺状态
      let status = await API_Shop.getShopStatus();
      // 获取店铺是否拥有显示供应商名称的权限
      const isSupplierName = await API_Shop.getIsShowSupplierName();
      store.dispatch("setIsSupplierName", isSupplierName);

      if (!status) {
        sessionStorage.removeItem("session");
        status = await API_Shop.getShopStatus();
      }

      if (status === "OPEN" || status === "CLOSED") {
        let rulesArray = [];
        const shopInfo = Storage.getItem("seller_shop");
        if (!shopInfo) await store.dispatch("getShopInfoAction");
        if (store.getters.addRouters.length === 0) {
          store
            .dispatch("GenerateRoutes")
            .then(() => {
              const { addRouters } = store.getters;

              router.addRoutes(addRouters);

              next({
                ...to,
                replace: true,
              });
            })
            .catch((e) => {
              console.log(e);

              errorMsg("验证失败，请重新登录！", "登录出错");
            });
        } else {
          const saleOpenCardRouteName = [
            "writeOffOpenCard",
            "closeCardWrite",
            "openCardWrite",
            "cardSearch",
            "couponOrderList",
            "saleCardOpenManagement",
            "saleCardCloseManagement"
          ];
          if (
            saleOpenCardRouteName.includes(to.name) ||
            saleOpenCardRouteName.includes(from.name)
          ) {
            rulesArray = await store.dispatch("GenerateRoutes", asyncRouterMap);
          }
          const routerMapList = [];
          store.getters.permission_routers.forEach((item) => {
            if (item.children) {
              routerMapList.push(item.name);
              item.children.forEach((r) => {
                if (r.children) {
                  routerMapList.push(r.name);
                  r.children.forEach((a) => {
                    routerMapList.push(a.name);
                  });
                } else {
                  routerMapList.push(r.name);
                }
              });
            } else {
              routerMapList.push(item.name);
            }
          });
          if (routerMapList.indexOf(to.name) !== -1) {
            next();
          } else {
            if (!rulesArray.length) {
              next({ path: "/no-sale-open" });
            } else {
              Vue.prototype.$message.error(
                "平台已关闭销售开卡功能，如有其他问题，请联系平台。"
              );
              if (
                from.name === store.getters.permission_routers[5].name ||
                from.name === store.getters.permission_routers[5].redirect.name
              ) {
                window.location.href =
                  window.location.protocol + "//" + window.location.host + "/";
              } else {
                next({ name: store.getters.permission_routers[5].name });
              }
            }
          }
        }
      } else if (status === "CLOSED") {
        errorMsg("当前商城已被禁用，无法登录。如需登录，请联系平台管理人员。");
      } else if (status === "APPLY" || status === "APPLYING") {
        let shopInfo = Storage.getItem("seller_shop");
        if (!shopInfo) {
          await store.dispatch("getShopInfoAction");
          shopInfo = Storage.getItem("seller_shop");
        }
        shopInfo = shopInfo ? JSON.parse(shopInfo) : {};
        if (shopInfo.shop_type === 2) {
          location.replace(
            `${domain.buyer_pc}/login_seller?forward=%2Fshop%2Fapply%2Fuser-agreement%3Ftype%3Dsupplier&type=supplier&name=${shopInfo.member_name}`
          );
        } else {
          errorMsg("您的店铺正在申请中，请稍后再试！");
        }
      } else if (status === "REFUSED") {
        errorMsg("您的开店申请被拒绝，请重新申请或联系管理员！");
      } else if (status === "EXPIRED") {
        errorMsg(
          "当前商城已到期，无法登录。如需登录，请联系平台管理人员进行充值续费"
        );
      } else {
        if (location.href.includes("login")) {
          Storage.clearAll();
        } else {
          store.dispatch("fedLogoutAction");
          MessageBox.confirm("账号尚未申请开店！", "出现错误", {
            confirmButtonText: "去开店",
            cancelButtonText: "取消",
            type: "error",
          }).then(() => {
            location.replace(`${domain.buyer_pc}/shop/apply`);
          });
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?forward=${location.pathname}`);
      NProgress.done();
    }
  }
}

const errorMsg = (msg, title = "权限错误") => {
  store.dispatch("fedLogoutAction");
  MessageBox.alert(msg, title, {
    type: "error",
    callback: () => {
      location.replace(`/login?forward=${location.pathname}`);
    },
  });
};

router.beforeEach(routerBeforeEach);

router.afterEach((info) => {
  const n = info.matched;
  if (n && n.length && !n[0].meta.nullChild) {
    store.dispatch("toggleMenuOpen", true);
  } else {
    store.dispatch("toggleMenuOpen", false);
  }
  NProgress.done();
});
