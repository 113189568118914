/**
 * Created by Andste on 2018/5/28.
 */

import request from '@/utils/request'
import { domain } from '@/../ui-domain'
import { RegExp } from '@/../ui-utils'
import * as API_goodsCategory from '@/api/goodsCategory'
import * as API_Shop from "@/api/shop";
import {getShopExtAuth} from "@/api/order";
export default {
	props: {
		// 选择器类型，后台管理：admin，商家中心：seller
		type: {
			type: String,
			default: 'admin'
		},
		// 显示dialog
		show: {
			type: Boolean,
			default: false
		},
		api: {
			type: String,
			default: 'admin/goods'
		},
		// 获取多个商品API
		multipleApi: {
			type: String,
			default: 'admin/goods/@ids/details'
		},
		// 分类API
		categoryApi: {
			type: String,
			default: 'admin/goods/categories/@id/children'
		},
		// 默认数据
		defaultData: {
			type: Array,
			default: () => ([])
		},
	},
	data() {
		return {
			/** 当前点击的1级分类索引*/
			activeCategoryIndex1: -1,

			/** 当前点击的2级分类索引*/
			activeCategoryIndex2: -1,
			/** 1级分类列表*/
			// storeTypeList: [{name:"积分商城",value:1},{name:"现金商城",value:2}],
			storeTypeList: [{name:"现金商城",value:2}, {name:"套餐卡商城",value:3}, {name:"智能套餐卡商城",value:4}],

			/** 2级分类列表*/
			storeList: [],

			/** 3级分类列表*/
			goodsList: [],
			/** 已选列表 */
			selectedList: [],
			dialogVisible: this.show,
			// 前台域名
			buyerDomain: domain.buyer_pc,
			baseInfoForm: {
				// category_id: ''
			},
			mall_type: null
		}
	},
	watch: {
		show(newVal) {
			console.log(newVal,"新变化的值?")
			this.dialogVisible = newVal
      this.getShopLv()
		},
		dialogVisible(newVal) {
			newVal === false && this.$emit('close')
		}
	},
	methods: {
		/** 查询下一级 商城商品分类*/
		GET_NextLevelCategory(row, level) {
			this.loading = true;
			this.mall_type = row.value
			let params = {
				mall_type: row.value
			}
			API_goodsCategory.getStoreList(params).then((response) => {
				this.loading = false
				if (level === 1) {
					this.storeList = response
				}
			})
		},
		/** 选择商城商品分类 */
		handleSelectCategory(row, index, level) {
			if (level === 1) {//选择商城类型
				this.activeCategoryName1 = row.name
				this.activeCategoryName2 = this.activeCategoryName3 = ''
				this.activeCategoryIndex1 = index
				this.activeCategoryIndex2 = -1
				this.GET_NextLevelCategory(row, level)
        this.$emit('storeType', index)
			} else if (level === 2) {//选择商城站点
				this.activeCategoryName2 = row.shop_name
				this.activeCategoryName3 = ''
				this.activeCategoryIndex2 = index
			} else {
				this.activeCategoryName3 = row.name
			}
			// 设置当前商城分类ID
			// this.baseInfoForm.category_id = row.category_id
			// this.GET_NextLevelCategory(row, level)
		},
		/** 确认 */
		handleConfirm() {
			if(this.activeCategoryIndex2 == -1){
				this.$message.error('请选择商家')
			}else{
				// this.$emit('confirm', this.storeList[this.activeCategoryIndex2])
				console.log(this.storeList[this.activeCategoryIndex2])
				this.$emit('confirm', { ...this.storeList[this.activeCategoryIndex2], mall_type: this.mall_type })
				this.$emit('close')
			}
		},
  //  获取店铺等级信息
    async getShopLv () {
      //判断是否有双选商城
      const resStatus = await getShopExtAuth();
      API_Shop.getShopLv().then(res => {
        if (!res.data.has_package_store) {//判断是否有套餐卡商城
          this.storeTypeList = res.data.has_intelligent_card20 ? [{name:"现金商城",value:2}, {name:"智能套餐卡商城",value:4}]: [{name:"现金商城",value:2}]
        } else {
          this.storeTypeList = res.data.has_intelligent_card20 ? [{name:"现金商城",value:2}, {name:"套餐卡商城",value:3}, {name:"智能套餐卡商城",value:4}]:[{name:"现金商城",value:2}, {name:"套餐卡商城",value:3}]
        }
        if (resStatus.double_choice_mall_open === "OPEN") {
          this.storeTypeList.push({name:"双选商城",value:5})
        }
        if (resStatus.drop_shipping_mall_open === "OPEN") {
          this.storeTypeList.push({ name: "一件代发商城", value: 7 })
        }
        if (resStatus.counting_card_open === "OPEN") {
          this.storeTypeList.push({name:"小程序商城",value:6})
        }
        /**百泽纳只有双选  大平台关闭后只剩下了现金商城 需要过滤 */
        this.storeTypeList=this.storeTypeList.filter(item=>item.name=='双选商城');
      })
    }
	}
}
