import Layout from '../../views/layout/Layout';
const pathPrefix = `/combo-card-shop`;
const nameSuffix = `ComboCard`;

/**
 * 注！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
 * 此页面中的name都带有后缀 ComboCard，使用name做路由跳转需要补充上，比如：
 * this.$router.push({name: "comboEditComboCard"});
 * 此页面中的一级菜单path都带有前缀 /combo-card-shop，使用path做路由跳转需要补充上，比如：
 * this.$router.push({path: "/combo-card-shop/goods"});(商品模块除外，见addPathPrefix函数的行内注释)
 */

const comboCardShopRouterMap = [
  // #region 商品
  {
    path: `/shop-goods`,
    component: Layout,
    redirect: `/shop-goods/goods-list`,
    name: `goods`,
    meta: {
      title: `goods`,
      icon: `goods`
    },
    children: [
      // 我的商品
      {
        path: `goods-list`,
        component: () => import('../../views/combo-card-shop/goods/goods/goods-list'),
        name: `goodsList`,
        meta: { title: `goodsList` }
      },
      // 集市精选
      {
        path: `market`,
        // component: () => import('../../views/combo-card-shop/goods/market/market'),
        component: () => import(`../../views/shopGoods/market`),
        name: `market`,
        meta: { title: `market` }
      },
      {
        path: 'price-change-record',
        name: 'PriceChangeRecord',
        component: () => import('@/pages/goods/pages/PriceChangeRecord/PriceChangeRecord'),
        meta: {title: 'PriceChangeRecord'}
      },
      {
        path: 'goods',
        component: () => import('../../views/shopGoods/goods'),
        name: 'shopGoodsa',
        hidden: true,
        meta: { title: 'shopGoodsa' }
      },
      {
        path: 'goods/:nav/:type',
        component: () => import('../../views/shopGoods/goods'),
        name: 'shopGoodsa',
        hidden: true,
        meta: { title: 'shopGoodsa', activeNames: ['marketComboCard'] }
      },
      {
        path: 'good/:from',
        component: () => import('../../views/page/pc-decoration/good'),
        name: 'marketGooda',
        hidden: true,
        meta: { title: 'marketGooda' }
      },
      // {
      //   path: `market-goods-list/:nav/:type`,
      //   component: () => import(`../../views/combo-card-shop/goods/market/market-goods-list`),
      //   name: `marketGoodsList`,
      //   hidden: true,
      //   meta: { title: `marketGoodsList` }
      // },
      // {
      //   path: `market-good/:from`,
      //   component: () => import(`../../views/combo-card-shop/goods/market/market-good`),
      //   name: `marketGood`,
      //   hidden: true,
      //   meta: { title: `marketGood` }
      // },
      // 套餐礼包
      {
        path: `combo-list`,
        component: () => import(`../../views/combo-card-shop/goods/combo/combo-list.vue`),
        name: `comboList`,
        meta: { title: `comboList` }
      },
      {
        path: `combo-edit/:id?`,
        component: () => import(`../../views/combo-card-shop/goods/combo/combo-edit.vue`),
        name: `comboEdit`,
        meta: {
          title: `comboEdit`,
          noCache: true,
          activeNames: ['comboListComboCard']
        },
        hidden: true
      },
    ]
  },
  // #endregion

  // #region  用户
  {
    path: `/users`,
    component: Layout,
    name: `users`,
    redirect: `/users/user-list`,
    meta: {
      title: `users`,
      icon: `user`
    },
    children: [
      {
        path: `user-list`,
        component: () => import(`../../views/combo-card-shop/users/userList`),
        name: `userList`,
        meta: { title: `userList` }
      },
      {
        path: `user-msg`,
        component: () => import(`../../views/combo-card-shop/users/userDetail`),
        name: `usersMsg`,
        hidden: true,
        meta: {
          title: `usersMsg`,
          activeNames: ["userListComboCard"]
        }
      },
      // {
      //   path: `fzadmin`,
      //   component: () => import(`../../views/users/fzadmin`),
      //   name: `fzadmin`,
      //   meta: { title: `fzadmin` }
      // }
    ]
  },
  // #endregion

  // #region 订单
  {

    path: '/order',
    component: Layout,
    name: 'order',
    redirect: '/order/order-list',
    meta: {
      title: 'order',
      icon: 'order'
    },
    children: [
      {
        path: 'order-list',
        component: () => import('../../views/combo-card-shop/order/orderList'),
        name: 'orderList',
        meta: { title: 'orderList' }
      },
      {
        path: ':route/detail/:sn',
        component: () => import('../../views/combo-card-shop/order/orderDetail'),
        name: 'orderDetail',
        hidden: true,
        meta: { title: 'orderDetail', activeNames: ['orderListComboCard'] }
      },
    ]
  },
  // #endregion

  // #region 卡券
  {
    path: `/card`,
    component: Layout,
    redirect: `/card/card-list`,
    name: `card`,
    meta: {
      title: `card`,
      icon: `finance`
    },
    children: [
      // 卡券
      {
        path: `card-list`,
        component: () => import(`../../views/combo-card-shop/card/card-list.vue`),
        name: `cardList`,
        meta: { title: `cardList` }
      },
      {
        path: `card-edit/:id?`,
        component: () => import(`../../views/combo-card-shop/card/card-edit.vue`),
        name: `cardEdit`,
        meta: {
          title: `cardEdit`,
          noCache: true,
          activeNames: ['cardListComboCard']
        },
        hidden: true
      },
      {
        path: `card-batch/:card_id?`, // 发放批次列表
        component: () => import(`@/views/combo-card-shop/card/card-batch.vue`),
        name: `cardBatch`,
        meta: {
          title: `cardBatch`,
          noCache: true,
          activeNames: ['cardListComboCard']
        },
        hidden: true
      },
      {
        path: `card-grant/:card_id/:log_id`,
        component: () => import(`../../views/combo-card-shop/card/card-grant.vue`),
        name: `cardGrant`,
        meta: {
          title: `cardGrant`,
          noCache: true,
          activeNames: ['cardListComboCard']
        },
        hidden: true
      },
      {
        path: `record-list`,
        component: () => import(`../../views/combo-card-shop/card/record-list.vue`),
        name: `recordList`,
        meta: { title: `recordList` },
        hidden: true
      },
      {
        path: `num-active-list/:card_id?`,
        component: () => import(`../../views/combo-card-shop/card/num-active-list.vue`),
        name: `numActiveList`,
        meta: { title: `numActiveList` },
        hidden: true
      },
      {
        path: `num-exp-list/:card_id?`,
        component: () => import(`../../views/combo-card-shop/card/num-exp-list.vue`),
        name: `numExpList`,
        meta: { title: `numExpList` },
        hidden: true
      },
      {
        path: `num-used-list/:card_id?`,
        component: () => import(`../../views/combo-card-shop/card/num-used-list.vue`),
        name: `numUsedList`,
        meta: { title: `numUsedList` },
        hidden: true
      },
    ]
  },
  // #endregion

  // #region 财务
  {
    path: '/finance',
    component: Layout,
    redirect: '/finance/deal-detailed',
    name: 'finance',
    meta: {
      title: 'finance',
      icon: 'finance'
    },
    children: [
      {
        path: 'deal-detailed',
        component: () => import('../../views/combo-card-shop/finance/dealDetailed'),
        name: 'dealDetailed',
        meta: { title: 'dealDetailed' }
      },
      {
        path: ':route/detail/:sn',
        component: () => import('../../views/combo-card-shop/finance/detail'),
        name: 'detail',
        hidden: true,
        meta: { title: 'detail', activeNames: ['financeComboCard'] }
      },
    ]
  },
  // #endregion

  // #region 通知
  {
    path: `/info`,
    component: Layout,
    redirect: `/info/infoList`,
    name: `info`,
    meta: {
      title: `info`,
      icon: `notify`
    },
    children: [
      {
        path: `infoList`,
        component: () => import(`../../views/combo-card-shop/info/infoList`),
        name: `infoList`,
        meta: { title: `infoList` }
      }
    ]
  },
  // #endregion

  // #region 设置
  {
    path: `/setting`,
    component: Layout,
    redirect: `/setting/shop-mesg`,
    name: `setting`,
    meta: {
      title: `setting`,
      icon: `settings`
    },
    children: [
      {
        path: `shop-mesg`,
        component: () => import(`../../views/combo-card-shop/setting/shopMessage`),
        name: `shopMesg`,
        meta: { title: `shopMesg` }
      },
      {
        path: `safeSetting`,
        component: () => import(`../../views/combo-card-shop/setting/safeSetting`),
        name: `safeSetting`,
        meta: { title: `safeSetting` }
      },
      {
        path: `shop-agree`,
        component: () => import(`../../views/combo-card-shop/setting/agreeIn`),
        name: `agreeIn`,
        meta: { title: `agreeIn` }
      },
      {
        path: `shop-transportg`,
        component: () => import(`../../views/combo-card-shop/setting/transportg`),
        name: `transportg`,
        meta: { title: `transportg` }
      },
    ]
  },
  // #endregion

  // #region 数据
  {
    path: '/statistics',
    component: Layout,
    redirect: '/statistics/shop-active',
    name: 'statistics',
    meta: {
      title: 'statistics',
      icon: 'data'
    },
    children: [
      {
        path: 'shop-active',
        component: () => import('../../views/combo-card-shop/statistics/analysis/shopActive'),
        name: 'shopActive',
        meta: { title: 'shopActive' }
      },
    ]
  },
  // #endregion

  // #region 店员
  {
    path: `/shop-auth`,
    component: Layout,
    redirect: `/shop-auth/shop-assistant`,
    name: 'shopAuth',
    meta: {
      title: 'shopAuth',
      icon: 'clerk'
    },
    children: [
      {
        path: 'shop-assistant',
        component: () => import('../../views/combo-card-shop/shop-auth/shopAssistant'),
        name: 'shopAssistant',
        meta: { title: 'shopAssistant' }
      },
      {
        path: 'role-manage',
        component: () => import('../../views/combo-card-shop/shop-auth/roleManage'),
        name: 'roleManage',
        meta: { title: 'roleManage' }
      },
      {
        path: 'role-permission/:id(\\d+)',
        component: () => import('../../views/combo-card-shop/shop-auth/rolePermission'),
        name: 'rolePermission',
        hidden: true,
        meta: {
          title: 'rolePermission',
          noCache: true,
          activeNames: ['roleManageComboCard']
        }
      }
    ]
  }
  // #endregion
]

/**
 * 给路由name统一加后缀以区别供应商、企业、店铺、套餐卡店铺
 * ../..param {*} routers
 * ../..returns
 */
function genComboCardShopRouterMap(routers) {
  routers.forEach(item => {
    item.name && (item.name += nameSuffix)
    item.children && (item.children = genComboCardShopRouterMap(item.children))
  })
  return routers
}

/**
 * 给路由path加前缀以区别供应商、企业、店铺、套餐卡店铺
 * ../..param {*} routers
 * ../..returns
 */
function addPathPrefix(routers) {
  routers.forEach((i, index) => {
    if (index > 0) { // 因为集市精选还是使用现金商城的集市精选，所以跳过商品菜单的修改，保持与现金商城路径一致
      i.path = pathPrefix + i.path;
      i.redirect = pathPrefix + i.redirect;
    }
  })
  return routers;
}

export default addPathPrefix(genComboCardShopRouterMap(comboCardShopRouterMap))
