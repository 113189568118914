/**
 * 销售员相关API
 */

import request from "@/utils/request";

/**
 * 获取销售员列表
 * @param data
 * @returns {Promise<any>}
 */
export function listSales(data) {
  return request({
    url: "seller/members/getSell",
    method: "post",
    data,
  });
}

/**
 * 添加销售员
 * @param data
 * @returns {Promise<any>}
 */
export function addSales(data) {
  return request({
    url: "seller/members/addSell",
    method: "post",
    data,
  });
}

/**
 * 删除销售员
 * @param id
 * @returns {Promise<any>}
 */
export function delSales(id) {
  return request({
    url: `seller/members/delSell/${id}`,
    method: "delete",
  });
}

/**
 * 查询销售员
 * @param id
 * @returns {Promise<any>}
 */
export function getSales(id) {
  return request({
    url: `seller/members/${id}`,
    method: "get",
  });
}

/**
 * 编辑销售员
 * @param data
 * @returns {Promise<any>}
 */
export function editSales(data) {
  return request({
    url: "seller/members/editSell",
    method: "put",
    data,
  });
}

// 查询销售人员列表
export function getSalesMan(params, loading) {
  return request({
    url: `seller/members/salesman`,
    method: "get",
    params,
    loading,
  });
}

// 启用/禁用
export function changeSalesManStatus(id, status) {
  return request({
    url: `seller/members/salesman/changeStatus/${id}`,
    method: "DELETE",
    params: {
      status,
    },
  });
}

// 添加销售人员
export function addSalesMan(params) {
  return request({
    url: `seller/members/salesman/add`,
    method: "POST",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

// 编辑销售人员
export function editSaleMan(params) {
  return request({
    url: `seller/members/salesman/edit/${params.id}`,
    method: "POST",
    data: params,
    headers: { "Content-Type": "application/json" },
  });
}

// 查询管理员管理销售人员列表
export function getSaleManIds(params) {
  return request({
    url: `seller/members/salesman/salesmanList/${params.id}`,
    method: "GET",
    params,
  });
}

// 添加/编辑管理员管理销售人员列表
export function addSaleManIds(params) {
  let { id, ...obj } = params;
  const { ids } = obj;
  if (ids.length == 0) {
    return request({
      url: `seller/members/salesman/addSalesman/${params.id}`,
      method: "POST",
      params: {
        ids: [0],
      },
      headers: { "Content-Type": "application/json" },
    });
  }
  return request({
    url: `seller/members/salesman/addSalesman/${params.id}`,
    method: "POST",
    params: obj,
    headers: { "Content-Type": "application/json" },
  });
}
