import request from '@/utils/request'
import Storage from '@/utils/storage'
import {api} from '@/../ui-domain'
import md5 from 'js-md5'


/**
 * token登陆
 * @param params
 */
export function tokenLogin(params) {
  return request({
    url: `seller/shops/getMemberByToken`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 普通登录
 * @param params
 */
export function login(params) {
  params.password = md5(params.password)
  return request({
    url: `seller/login`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 手机号登录
 * @param {*} params 
 * @returns 
 */
export function loginDropShipping (params) {
  return request({
    url: `/seller/login/loginClerk/${params.mobile}`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 发送验证码
 * @param {*} mobile 手机号 
 * @param {*} member_type 用户类型
 * @param {*} uuid uuid客户端的唯一标识 
 * @param {*} captcha 图片验证码 可选 
 * @returns 
 */
export function smscodeDropShipping ({mobile,member_type,  uuid,captcha}) {
  return request({
    url: `/seller/login/smscodeClerk/${mobile}`,
    method: 'post',
    loading: false,
    params:{member_type,uuid,captcha}
  })
}

/**
 * 退出账户
 * @returns {*}
 */
export function logout() {
  return request({
    url: `seller/members/logout?uid=${Storage.getItem('seller_uid')}`,
    method: 'post'
  })
}

/**
 * 获取用户角色权限
 * @param role_id
 * @returns {*}
 */
export function getUserRolesPermissions(role_id) {
  return request({
    url: `seller/shops/roles/${role_id}/checked`,
    method: 'get',
    loading: false
  })
}

/**
 * 商家判断是否为企业用户
 * @param params
 */
export function getLoginType() {
  return request({
    url: '/seller/shops/getLoginType',
    method: 'get',
    loading: false
  })
}

/**
 * 查询店铺额外功能列表，用于列表查询
 * @param params
 */
 export function getShopExtAuthList() {
  return request({
    url: '/seller/shops/shopExtAuth/list',
    method: 'post',
    loading: false
  })
}

/**
 * 获取用户角色权限
 */
export function getAutoUpDownStatus() {
  return request({
    url: `seller/statistics/intelligentoperation/getAutoUpDownStatus`,
    method: 'get'
  })
}