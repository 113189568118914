<template>
  <div class="sale-search" v-clickoutside="close">
    <el-input
      size="medium"
      ref="saleSearchInput"
      v-model.trim="keyword"
      :maxlength="16"
      @input="inputKeyword"
      @blur="handleBlur"
      @focus="showContent"
      v-bind="$attrs"
    >
      <i class="el-icon-caret-bottom el-input__icon" slot="suffix"></i>
    </el-input>
    <!-- <div class="sale-content" v-show="show" :style="contentStyle"> -->
    <div class="sale-content" v-show="show">
      <div class="sale-list">
        <ul v-if="list.length > 0">
          <li
            v-for="item in list"
            :key="item.id"
            @click.stop="selectItem(item)"
            :class="{ active: keyword == item.name }"
          >
            <i class="el-icon-user-solid"></i>
            {{ item.name }}<span v-if="item.mobile">（{{ item.mobile }}）</span>
          </li>
        </ul>
        <div class="sale-null" v-else>暂无数据</div>
        <div class="sale-loading" v-show="isLoading">
          <i class="el-icon-loading"></i>
        </div>
      </div>
      <div class="add-sale-btn" v-if="!addBox" @click.stop="showAddBox">
        <div>添加销售人员</div>
      </div>
      <div class="add-sale-box" v-else>
        <el-form :inline="true" :model="addform" class="add-form-inline">
          <el-form-item :class="{ active: foucsIndex == 0 }">
            <el-input
              v-model.trim="addform.name"
              maxlength="16"
              @focus="foucsIndex = 0"
              @blur="foucsIndex = -1"
              placeholder="人员名称"
            ></el-input>
          </el-form-item>
          <el-form-item :class="{ active: foucsIndex == 1 }">
            <el-input
              v-model.trim="addform.mobile"
              @focus="foucsIndex = 1"
              @blur="foucsIndex = -1"
              placeholder="手机号"
            ></el-input>
          </el-form-item>
          <div class="add-fns">
            <i class="el-icon-check" @click.stop="addSaleForm"></i>
            <i class="el-icon-close" @click.stop="hideAddBox"></i>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import { getSalesMan, addSalesMan } from "@/api/sales";

export default {
  directives: { Clickoutside },
  name: "EnSaleSearch",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      keyword: "",
      list: [],
      interval: 500,
      show: false,
      foucsIndex: -1,
      addBox: true,
      isLoading: false,
      timer: null,
      dataList: [],
      addform: {
        name: "",
        mobile: "",
      },
      contentStyle: {
        left: 0,
        top: 0,
        width: 0,
        position: "fixed",
      },
    };
  },
  watch: {
    $attrs(v) {},
    value(v, o) {
      this.keyword = v;
    },
    addBox(v) {
      if (!v) {
        this.addform = {
          name: "",
          mobile: "",
        };
        this.foucsIndex = -1;
      }
    },
    show(v) {
      // const { disabled } = this.$attrs;
      // if (disabled) return;
      this.addBox = false;
      this.keyword = this.value;
      // const { left, top, width,height } =
      //   this.$refs.saleSearchInput.$el.getBoundingClientRect();
      // this.contentStyle = {
      //   left: left + "px",
      //   top: top + height + "px",
      //   width: width + "px",
      //   position: "fixed"
      // };
      if (!v) {
        this.isLoading = false;
      } else {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    },
  },
  mounted() {
    this.keyword = this.value;
    const { disabled } = this.$attrs;
    if (disabled) return;
    this.getList();
  },
  methods: {
    inputKeyword() {
      this.timer && clearTimeout(this.timer);
      this.isLoading = true;
      // if(this.keyword === "") {
      //   this.list.length = 0;
      //   this.isLoading = false;
      //   return;
      // }
      this.timer = setTimeout(() => {
        this.getList();
        console.log("s");
      }, this.interval);
    },
    addSaleForm() {
      if (this.addform.name === "") {
        return this.$message.warning("请输入人员名称");
      }
      if (this.addform.mobile === "") {
        return this.$message.warning("请输入手机号");
      }
      if (!/^1\d{10}$/.test(this.addform.mobile)) {
        return this.$message.warning("请输入正确的手机号");
      }
      addSalesMan(this.addform).then((res) => {
        this.$message.success("人员添加成功");
        this.getList();
        this.hideAddBox();
      });
    },
    handleBlur() {
      // this.$nextTick(()=>{
      //   console.log(this.keyword)
      //   this.$emit("blur", this.keyword);
      // })
    },
    checkKeyword() {
      if (this.list.length === 0) return false;
      let obj = this.list.find((item) => item.name === this.keyword);
      if (!obj) return false;
      return true;
    },
    close() {
      const { disabled } = this.$attrs;
      if (disabled) return;
      if (!this.checkKeyword()) {
        this.keyword = "";
        this.list.length = 0;
        this.timer && clearTimeout(this.timer);
      }
      this.$emit("input", this.keyword);
      this.hideContent();
    },
    showContent() {
      const { disabled } = this.$attrs;
      if (disabled) return;
      this.show = true;
    },
    hideContent() {
      const { disabled } = this.$attrs;
      if (disabled) return;
      this.show = false;
    },
    showAddBox() {
      this.addBox = true;
    },
    hideAddBox() {
      this.addBox = false;
    },
    selectItem(item) {
      const { disabled } = this.$attrs;
      if (disabled) return;
      this.keyword = item.name;
      this.$emit("change", item);
      this.close();
    },
    getList() {
      this.isLoading = true;
      getSalesMan(
        {
          status: 1,
          name: this.keyword,
          page_no: 1,
          page_size: 99999,
        },
        false
      )
        .then((response) => {
          this.isLoading = false;
          this.dataList = response.data;
          this.list = [...this.dataList];
          this.allChecked = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    fuzzyQuery(list, key, keyWord) {
      let arr = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i][key].match(keyWord) != null) {
          arr.push(list[i]);
        }
      }
      return arr;
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
$m-color: #1a43a9;

.add-fns {
  margin-left: auto;
  vertical-align: middle;
  position: relative;
  display: flex;
  i {
    cursor: pointer;
    position: relative;
    z-index: 1;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    transition: 0.2s all ease-out;
    &:first-of-type {
      border: 1px solid rgb(41, 167, 104);
      color: rgb(41, 167, 104);
      margin-right: 3px;
      &:hover {
        background-color: rgb(41, 167, 104);
        color: white;
        transform: scale(1.2);
        z-index: 2;
      }
    }
    &:nth-of-type(2) {
      border: 1px solid rgb(219, 84, 54);
      color: rgb(219, 84, 54);
      &:hover {
        background-color: rgb(219, 84, 54);
        color: white;
        transform: scale(1.2);
        z-index: 2;
      }
    }
  }
}

.add-form-inline {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 5px;
  /deep/ input {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0 5px;
    font-size: 12px;
    border-color: #dcdfe6 !important;
    &:focus {
      outline: none;
      border-color: #1a43a9 !important;
    }
  }
  .el-form-item {
    // flex: 1;
    width: 50%;
    margin-right: 5px;
    transition: 0.24s width;
    &.active {
      width: 100%;
    }
    /deep/ .el-form-item__content {
      width: 100%;
    }
  }
}
.sale-search {
  position: relative;
}
.add-sale-box {
  width: 100%;
  height: 40px;
  border-top: 1px solid #e4e7ed;
  .el-form-item {
    margin-bottom: 0px !important;
  }
}
.add-sale-btn {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $m-color;
  cursor: pointer;
  color: white;
}
.sale-content {
  z-index: 2013;
  position: absolute;
  top: 34px;
  left: 0;
  right: 0;
  transform-origin: center top;
  z-index: 2003;
  margin: 5px 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #e4e7ed;
  box-sizing: border-box;
  background-color: white;
  overflow: hidden;
  animation: 0.2s sale-search-show;
  .sale-null {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 40px;
    color: #9d9d9d;
  }
  .sale-list {
    position: relative;
    overflow: hidden;
    min-height: 40px;
    font-weight: normal;
    color: #333;
    ul {
      height: auto;
      max-height: 200px;
      overflow: auto;
    }
    .sale-loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.8);
    }
    /deep/ .el-form-item .el-input__inner {
      border-color: #dcdfe6 !important;
    }
  }
  ul,
  li {
    list-style: none;
    margin: 0;
    text-align: left;
    box-sizing: border-box;
    padding: 0;
    position: relative;
  }
  li {
    padding: 0px 10px;
    cursor: pointer;
    box-sizing: border-box;
    transition: 0.25s all;
    &.active {
      color: $m-color;
      font-weight: bold;
    }
    &:hover {
      background: #f9f9f9;
      padding-left: 15px;
      color: $m-color;
    }
    &:not(:last-of-type)::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 7px;
      right: 7px;
      background-color: #f9f9f9;
      height: 1px;
    }
  }
}

@keyframes sale-search-show {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
