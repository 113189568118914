/**
 * Created by Andste on 2018/7/2.
 * base    : 基础业务API
 * buyer   : 买家API
 * seller  : 商家中心API
 * admin   ：后台管理API
 */
const env = process.server
  ? process.env
  : (global.window ? window.__NUXT__.state.env : {});

let dev;
const reg = /^(?:[1-9]\d*|0)(?:\.\d+)?$/;
let ip = location.host.split('.')[0]
if (reg.test(ip) || location.host.includes('two')) {
  dev = {
    base: 'https://testtwoapifcbase.sshlqf.com',
    buyer: 'https://testtwoapifcbuyer.sshlqf.com',
    seller: 'https://testtwoapifcseller.sshlqf.com',
    admin: 'https://testtwoapifcadmin.sshlqf.com'
  };
} else {
  dev = {
    base:'https://apibase.bzoe.com.cn',
    buyer: 'https://apibuyer.bzoe.com.cn',
    seller: 'https://apiseller.bzoe.com.cn',
    admin: 'https://apiadmin.bzoe.com.cn',
  };
}

const pro = {
  base: env.API_BASE || 'https://apibase.bzoe.com.cn',
  buyer: env.API_BUYER || 'https://apibuyer.bzoe.com.cn',
  seller: env.API_SELLER || 'https://apiseller.bzoe.com.cn',
  admin: env.API_ADMIN || 'https://apiadmin.bzoe.com.cn',
}

module.exports = {
  dev,
  pro,
};
