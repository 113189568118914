import request from "@/utils/request";
import { domain } from "@/../ui-domain";
import { RegExp } from "@/../ui-utils";
import { getSalesMan } from "@/api/sales";

export default {
  props: {
    // 选择器类型，后台管理：admin，商家中心：seller
    type: {
      type: String,
      default: "seller",
    },
    // 显示dialog
    show: {
      type: Boolean,
      default: false,
    },
    // 默认数据
    defaultData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      authList: [],
      selectList: [],
      dialogVisible: this.show,
      allChecked: false,
      searchName: "",
      loading: false,
      dataList: "",
      searchStatus: false,
    };
  },
  watch: {
    show(newVal) {
      this.dialogVisible = newVal;
      if (newVal) this.init();
      else {
        this.authList.length = 0;
        this.allChecked = false;
      }
    },
    dialogVisible(newVal) {
      newVal === false && this.$emit("close");
    },
    // searchName(newVal) {
    //   if (newVal == "") return (this.selectList = this.dataList);
    //   this.selectList = [].filter.call(this.dataList, (item) => {
    //     let list = this.fuzzyQuery(this.dataList, "name", newVal);
    //     return list.some((obj) => obj.id == item.id);
    //   });
    // },
    allChecked(newVal) {
      if (!this.selectList.length) return;
      if (this.searchStatus) return;
      for (let i = 0; i < this.selectList.length; i++) {
        this.$set(this.selectList, i, {
          ...this.selectList[i],
          checked: newVal,
        });
      }
      this.handleToAuth();
    },
  },
  methods: {
    init() {
      this.searchName = "";
      this.allChecked = false;
      [].forEach.call(this.defaultData, (item, index) => {
        this.$set(this.authList, index, {
          ...item,
          checked: true,
        });
      });
      this.getSelectList();
    },
    changeAuth(bool, item) {
      if (bool) {
        if (!this.authList.some((obj) => obj.id == item.id)) {
          this.authList.push({
            ...item,
          });
        } else {
          this.filterAutoItem(item);
        }
      } else {
        this.filterAutoItem(item);
      }
    },
    delAuth(item) {
      this.filterAutoItem(item);
      let findIndex = this.selectList.findIndex((obj) => obj.id == item.id);
      if (findIndex >= 0) {
        this.$set(this.selectList, findIndex, {
          ...this.selectList[findIndex],
          checked: false,
        });
      }
    },
    filterAutoItem(item) {
      this.authList = this.authList.filter((obj) => obj.id != item.id);
    },
    handleToAuth() {
      // let list = this.getCheckList(this.selectList);
      // if (list.length <= 0) {
      //   return this.$message.error("请选择人员");
      // }
      this.selectList.forEach((item) => {
        const { checked } = item;
        if (checked) {
          if (!this.authList.find((obj) => obj.id == item.id)) {
            this.authList.push({
              ...item,
            });
          }
        } else {
          this.filterAutoItem(item);
        }
      });
    },
    getCheckList(list) {
      return [].filter.call(list, (item) => item.checked);
    },
    changeChecked(bool) {
      console.log(bool);
    },
    handleSearch() {
      // if (this.searchName == "") return;
      this.getSelectList();
    },
    getSelectList() {
      // this.selectList = [
      //   {
      //     name: "张三",
      //     checked: false,
      //     tel: Math.floor(10000000 * Math.random()) + 10000000,
      //     id: Math.floor(10000000 * Math.random()) + 10000000,
      //   },
      //   {
      //     name: "李四",
      //     checked: false,
      //     tel: Math.floor(10000000 * Math.random()) + 10000000,
      //     id: Math.floor(10000000 * Math.random()) + 10000000,
      //   },
      //   {
      //     name: "王五",
      //     checked: false,
      //     tel: Math.floor(10000000 * Math.random()) + 10000000,
      //     id: Math.floor(10000000 * Math.random()) + 10000000,
      //   },
      //   {
      //     name: "赵六",
      //     checked: false,
      //     tel: Math.floor(10000000 * Math.random()) + 10000000,
      //     id: Math.floor(10000000 * Math.random()) + 10000000,
      //   },
      //   {
      //     name: "孙七",
      //     checked: false,
      //     tel: Math.floor(10000000 * Math.random()) + 10000000,
      //     id: Math.floor(10000000 * Math.random()) + 10000000,
      //   },
      // ];
      this.loading = true;
      this.searchStatus = true;
      this.allChecked = false;
      this.dataList = []
      getSalesMan({
        status: 1,
        page_no: 1,
        page_size: 99999,
        name: this.searchName,
      })
        .then((response) => {
          this.loading = false;
          this.dataList = response.data;
          this.selectList = [].map.call(this.dataList,(item) => {
            if (this.authList.find((obj) => obj.id == item.id)) {
              item.checked = true;
            } else {
              item.checked = false;
            }
            return item;
          });
          this.searchStatus = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleConfirm() {
      let list = this.getCheckList(this.authList);
      // if (list.length <= 0) {
      //   return this.$message.error("请选择人员");
      // }
      this.$emit("confirm", {
        list,
      });
      this.$emit("close");
    },
    fuzzyQuery(list, key, keyWord) {
      let arr = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i][key].match(keyWord) != null) {
          arr.push(list[i]);
        }
      }
      return arr;
    },
  },
};
