
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import { XDialogProxy } from "./dialog.proxy";

@Component({
  name: "x-dialog"
})
export default class XDialog extends Vue {
  @PropSync("proxy", { required: true }) dialogProxy!: XDialogProxy;
  viewStyle = {};

  @Watch("dialogProxy", { immediate: true })
  proxyChange(proxy: XDialogProxy) {
    proxy.config.refreshPage = () => this.$forceUpdate();
  }

  @Watch("dialogProxy.visible", { immediate: true })
  visibleChange(visible: boolean) {
    if (visible) {
      setTimeout(() => this.calcHeight(), 200);
    }
  }

  calcHeight() {
    const container: HTMLElement = (this.$refs.container as any).$el;
    const wrapper = container.children[0];
    const header = wrapper.children[0];
    const body = wrapper.children[1];
    const footer = wrapper.children[2];

    const maxBodyHeight =
      document.body.clientHeight * 0.9 -
      header.clientHeight -
      (footer?.clientHeight || 0);

    if (body.clientHeight > maxBodyHeight) {
      this.viewStyle = {
        height: `${maxBodyHeight}px`
      };
    } else {
      this.viewStyle = {};
      // this.viewStyle = {
      //   height: `${body.clientHeight + 8}px`
      // };
    }
  }

  handleResp(resp: any) {
    if (typeof resp === "boolean") {
      resp && this.dialogProxy.dismiss();
    } else if (!resp) {
      this.dialogProxy.dismiss();
    } else if (resp.then) {
      resp.then(flag => {
        flag && this.dialogProxy.dismiss();
      });
    }
  }

  cancel() {
    const { beforeCancel } = this.dialogProxy.config;
    const resp = beforeCancel ? beforeCancel() : true;
    this.handleResp(resp);
  }

  async confirm() {
    const { beforeConfirm } = this.dialogProxy.config;
    if (!beforeConfirm) return this.handleResp(true)
    const resp = await beforeConfirm()
    console.log(resp)
    this.handleResp(resp);
  }

  close() {
    const { beforeClose } = this.dialogProxy.config;
    const resp = beforeClose ? beforeClose() : true;
    this.handleResp(resp);
  }

  handleOpened() {
    const { afterDisplay } = this.dialogProxy.config;
    afterDisplay && afterDisplay();
  }

  handleClosed() {
    const { afterDismiss } = this.dialogProxy.config;
    afterDismiss && afterDismiss();
    this.viewStyle = {};
  }
}
